import HeroHeaderBox from "./heroHeaderBox";
import SVG from "react-inlinesvg";

const HeroHeader = ({ data, placeholders }) => {
  return (
    <div className="">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          <div className="relative h-full">
            <svg
              className="absolute right-full translate-x-1/4 translate-y-1/3 transform sm:translate-x-1/2 md:translate-y-1/2 lg:translate-x-full"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
              />
            </svg>
            <svg
              className="absolute left-full -translate-x-1/4 -translate-y-3/4 transform sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
              />
            </svg>
          </div>
        </div>

        <div className="relative pb-8">
          <div className="container mt-16">
            <div className="flex flex-col items-center justify-center space-y-5">
              {/* New Feature Badge */}
              <div className="absolute -top-6 left-1/2 mb-3 -translate-x-1/2 transform">
                <span className=" mb-3 inline-flex items-center justify-center rounded-full bg-gradient-to-tr from-red-500 to-yellow-300 px-4 py-1 pb-1.5 pt-1  text-sm font-medium leading-5 text-white ">
                  New Feature
                </span>
              </div>

              <div className="space-between flex w-full flex-col justify-center rounded-3xl bg-gradient-to-br from-ifixers via-ifixers to-emerald-300 p-8 align-middle md:p-16 md:pt-12">
                <h1 className="text-center text-5xl font-bold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
                  <span className="block text-center text-white">
                    {data.title1} {data.title2}
                  </span>
                </h1>
                <p className="mx-auto mt-6 max-w-md text-center text-base font-medium text-slate-50 sm:text-lg md:max-w-3xl md:text-xl">
                  {data.chatbox_subtitle}
                </p>
                <iframe
                  className="mx-auto mt-8 h-[400px] w-full max-w-2xl rounded-3xl lg:h-[500px]"
                  src="https://rocky.ifixers.be/chatbot-iframe/zofBwcPo-NCkllIH9Lqq-"
                ></iframe>
              </div>

              <p className="mx-auto max-w-md text-center text-base font-medium text-gray-500 sm:text-lg md:mt-6 md:max-w-3xl md:text-xl">
                {data.subtitle}
              </p>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="hidden w-full flex-1 lg:flex " />
          </div>
          <div className="container flex flex-col justify-between space-y-6 pb-5 lg:flex-row lg:space-x-12 lg:space-y-0">
            {data.box.map((box, index) => (
              <HeroHeaderBox
                key={box.id}
                applyOpenHours={index === 0}
                title={box.title}
                subText={box.sub_title}
                href={box.menu_link.href}
                image={placeholders[index]}
                incentives={box.incentives}
                className="relative w-full"
              />
            ))}
          </div>
        </div>
      </div>
      <div className="">
        <div className="mx-auto mt-16 max-w-7xl px-4 sm:px-6 lg:mt-24 lg:px-8">
          <h2 className="text-center text-sm font-semibold uppercase tracking-wide text-gray-400">
            {data.affiliates.title}
          </h2>
          <div className="mt-4 grid grid-cols-2 gap-8 md:grid-cols-8">
            {data.affiliates.partners.slice(0, 8).map((partner) => (
              <div
                key={partner.id}
                className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
              >
                <Affiliate
                  src={partner.logo_svg}
                  alt={`${partner.name} logo`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroHeader;

const Affiliate = ({ src, alt, className }) => {
  return (
    <div className={`col-span-1 ${className}`}>
      <div>
        <SVG
          alt={alt}
          src={src}
          className="h-16 max-w-[12rem] xl:max-w-[16rem]"
        />
      </div>
    </div>
  );
};
